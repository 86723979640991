@import './colors.scss';

@font-face {
    font-family: "CatChilds";
    src: url(./../fonts/CATChilds.ttf) format("truetype");
}

html{
    @media only screen and (max-width: 560px) {
        font-size:12px;
    }
}

body{
    margin: 0;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.logo-image{
    content:url("./../images/Adventuries_Logo_Bildmarke.svg");
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    display:inline-block;
    &.small {
        height: 2rem;
    }
    &.med {
        height: 4rem;
    }
    &.large {
        height: 6rem;
    }
    &.huge {
        height: 8rem;
    }
}

.frame {
    display:grid;
    grid-template-columns: 100vw;
    grid-template-rows: 4rem minmax(0, 1fr);
    grid-template-areas: "header" "main";
    height: 100vh;
    width: 100vw;
    overflow:auto;

    background-color: black;

    .header {
        font-family: "CatChilds", Verdana, Tahoma;
        grid-area: header;
        background-color: $color1;
        color: $color1-font;
        
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 5fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: "logo spacer nav login";

        .logo {
            grid-area: logo;
            display: block;
            background-color: $color1;
            color: $color1-font;
            text-align:right;
    
            
            
        }
        .nav {
            grid-area: nav;
            a{
                text-decoration: none;
                color:$color1-font;
            }
                margin: 0;
                padding:0;
            
                position: relative;
                width: 20rem;
                height: 100%;
                background-color: $color1;
                border-radius: .5em;
                font-size: 1rem;
            .nav-button {
                line-height: 100%;
                height: 100%;
                font-size: 1.5rem;
                display: inline-block;
                position: relative;
                z-index: 1;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;
                line-height: 4rem;
                color: white;
                cursor: pointer;
            }
            .animation {
                position: absolute;
                height: 100%;
                top: 0;
                z-index: 0;
                transition: all .5s ease 0s;
                border-radius: 8px;
            }
            .nav-button:nth-child(1) {
                width: 10rem;
            }
            .nav-button:nth-child(2) {
                width: 10rem;;
            }
            .start-home, .nav-button:nth-child(1):hover~.animation {
                width: 10rem;
                left: 0;
                background-color: rgb(75, 75, 75);
            }
            .start-contact, .nav-button:nth-child(2):hover~.animation {
                width: 10rem;
                left: 10rem;
                background-color: rgb(55, 55, 55);
            }
        }
        .login {
            grid-area: login;
            text-align:right;
        }
    }
    
    
    .main-container{
        grid-area: main;
        background-image: url(./../images/landscape1.png);
        background-repeat:no-repeat;
        background-size:cover;
        padding: 0;
        margin: 0;
        background-color: $color2;
        overflow:auto;
        overflow-x: hidden;
        .main {
            background:none;
            color: $color2-font;

            display: grid;
            grid-template-columns: 20vw 60vw 20vw;
            @media only screen and (max-width: 560px) {
                grid-template-columns: 10vw 80vw 10vw;
            }
            grid-template-rows: minmax(calc(100vh - 13rem), 1fr) auto;
            grid-template-areas: "leftspacer content rightspacer" "footer footer footer";
            
            .content {               
                grid-area: content;
                .glassbox {
                    display: grid;
                    grid-template-columns: max-content auto max-content;
                    grid-template-rows: 1fr;
                    grid-template-areas: "imageleft text imageright";
                    @media only screen and (max-width: 560px) {
                        grid-template-columns: auto;
                        grid-template-rows: auto auto auto;
                        grid-template-areas: "imageleft" "text" "imageright";
                    }
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 8px;
                    margin-left:0;
                    margin-right:0;
                    margin-top:0.5rem;
                    margin-bottom:0.5rem;
                    padding: 0.5rem;
                    padding-left:3vw;
                    padding-right:3vw;
                    a{
                        color:white;
                    }
                    .text{
                        grid-area: text;
                        h1,h2{
                            text-align:center;
                        }
                        
                    }
                    .image-right{
                        grid-area: imageright;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .image-left{
                        grid-area: imageleft;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
                .bannerbox {
                    align-content: center;
                    margin-top: 0;
                    margin-left: 0;
                    margin-right:0;
                    margin-bottom:0;
                    z-index:100;
                    .bannerstatic{
                        content:url("./../images/Adventuries_Logo_Wort-Bildmarke\ Claim_Banner.svg");
                        -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
                        filter: invert(100%);
                        z-index:0;
                        width:60vw;
                        @media only screen and (max-width: 560px) {
                            width:80vw;
                        }
                        object-fit:cover;
                    }
                }

                .flipbox{
                    display: flex;
                    @media only screen and (max-width: 560px) {
                        display: block;
                    }
                    justify-content: space-between;
                    padding-left:1vw;
                    padding-right:1vw;
                    .flip-card {
                        display:block;
                        background-color: transparent;
                        width: 19vw;
                        height: 19vw;
                        font-size: 1.2vw;
                        @media only screen and (max-width: 560px) {
                            width: 80vw;
                            height: 80vw;
                            font-size: 4vw;
                            margin-bottom:2vw;
                        }
                        
                        perspective: 1000px; /* Remove this if you don't want the 3D effect */
                      }
                    .flip-card-inner {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        transition: transform 0.8s;
                        transform-style: preserve-3d;
                      }
                    .flip-card:hover .flip-card-inner:hover {
                        transform: rotateY(180deg);
                      }
                      
                      /* Position the front and back side */
                      .flip-card-front, .flip-card-back {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        -webkit-backface-visibility: hidden; /* Safari */
                        backface-visibility: hidden;
                      }
                      
                      /* Style the front side (fallback if image is missing) */
                      .flip-card-front {
                        border-radius: 8px;
                        background-color: rgba(0, 0, 0, 0.575);
                        color: white;
                        transform: rotateY(0deg);
                      }
                      
                      /* Style the back side */
                      .flip-card-back {
                        border-radius: 8px;
                        background-color: rgba(0, 0, 0, 0.575);
                        color: white;
                        transform: rotateY(180deg);
                        padding-left:1rem;
                        padding-right:1rem;
                      }
                }
            }
            .leftspacer{
                grid-area:leftspacer;
                background:none;
            }
            .rightspacer{
                grid-area:rightspacer;
                background:none;
            }
            .footer {
                grid-area: footer;
                background-color: $color1;
                color: $color1-font;
                a{
                    color:white;
                }
                padding: 1rem;
                text-align: center;
                div {
                    display:inline-block;
                    vertical-align: middle;
                    margin-left: 1rem;
                    margin-right: 1rem;
                   
                }
                .foerderlogos{
                    width:100%;
                    div{
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                }
                
            }
            
        }
    }
    //Images
    .imgIdea{
        display:inline-block;
        content:url("./../images/imgIdea.png");
        -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
        z-index:0;
        width:100%;
        object-fit:cover;
    }
    .imgPlay{
        display:inline-block;
        content:url("./../images/imgPlay.png");
        -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
        z-index:0;
        width:100%;
        object-fit:cover;
    }
    .imgCommunity{
        display:inline-block;
        content:url("./../images/imgCommunity.png");
        -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
        z-index:0;
        width:80%;
        object-fit:cover;
    }

    .logo{
        display:inline-block;

        &.sab{
            content:url("./../images/foerderlogos/SMWA_ESF_LO_Sachsen_2019_QUER_RGB.png");
        }
        &.bmwi{
            content:url("./../images/foerderlogos/BMWi-Foerderlogo_4c_jpeg.jpg");
        }
        &.esf{
            content:url("./../images/foerderlogos/Claim-ESF-cmyk-jpg.jpg");
        }
        &.esf2{
            content:url("./../images/foerderlogos/Logo-ESF-rgb-jpg.jpg");
        }
        &.eu{
            content:url("./../images/foerderlogos/Logo-EU-cmyk-jpg.jpg");
        }
        &.exist{
            content:url("./../images/foerderlogos/Logo-EXIST-png.png");
            background-color:white;
        }
        &.saxeed{
            content:url("./../images/foerderlogos/Logo-Saxeed.png");
            background-color:white;
        }
        &.spielpaedagoge{
            content:url("./../images/spielpaedagoge/Logo_Spielpädagoge_rgb.png");
        }

        &.small {
            height: 2rem;
        }
        &.med {
            height: 4rem;
        }
        &.large {
            height: 6rem;
        }
        &.huge {
            height: 12rem;
        }

    }



}